import { TailSpin } from 'react-loader-spinner';
import "./LoadingSpinner.css";

enum LoadingSpinnerSize {
    SMALL = '12px',
    LARGE = '24px'
};

type LoadingSpinnerProps = {
    size?: LoadingSpinnerSize;
};

const LoadingSpinner = ({ size = LoadingSpinnerSize.SMALL }: LoadingSpinnerProps) => {
    return (
        <div className='loading-spinner'>
            <TailSpin
                visible={true}
                height={size}
                width={size}
                color="var(--text-primary)"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}
                }
                wrapperClass=""
            />
        </div>
    )
};

export { LoadingSpinnerSize };
export default LoadingSpinner;