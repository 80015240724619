import React, { useEffect, useState } from "react";
import { useFileState } from "../providers/FileStateProvider";
import { filterFiles } from "../lib/uploadUtils";
import "./FileUploadInput.css"
import DropZone from "./Shared/DropZone";
import './DicomSelector.css'
import './Shared/DropZone.css';
import CheckBox from "./Shared/CheckBox";
import LoadingSpinner, { LoadingSpinnerSize } from "./Shared/LoadingSpinner";

const DicomSelector = ({ disabled }: { disabled?: boolean }) => {
    const { api, files: existingFiles, onlyIncludeOriginalImages, status } = useFileState()


    // We use some local state so that we can try to get the loading spinner to start as soon as possible
    const [isParsingFiles, setIsParsingFiles] = useState<boolean>(false)

    const onSelectInput = () => {
        setIsParsingFiles(true);
    };

    useEffect(() => {
        setIsParsingFiles(status.isParsingFiles);
    }, [status]);

    const selectFilesHandler = (event: any): void => {
        const filteredFiles = filterFiles(event.target.files)

        api.addFiles(existingFiles, filteredFiles, onlyIncludeOriginalImages)
    };

    const onDrop = (files: File[]): void => {
        const filteredFiles = filterFiles(files);

        api.addFiles(existingFiles, filteredFiles, onlyIncludeOriginalImages)
    }

    const toggleOnlyIncludeOriginalImages = () => {
        api.setOnlyIncludeOriginalImages(!onlyIncludeOriginalImages);
    };

    const primaryClassName = disabled ? "custom-file-upload-primary-disabled" : "custom-file-upload-primary"

    return (
        <div className="dicom-selector">
            <div className="dicom-selector-original-images-checkbox">
                <CheckBox
                    title="Only Include Original Images"
                    value={onlyIncludeOriginalImages}
                    onToggle={toggleOnlyIncludeOriginalImages}
                />
            </div>
            <div className="dicom-selector-select-folder">
                <label htmlFor="folder-selector" className={primaryClassName}>
                    Select Folder
                </label>
                <input
                    id="folder-selector"
                    disabled={disabled}
                    type="file"
                    onChange={selectFilesHandler}
                    onInput={onSelectInput}
                    directory=""
                    webkitdirectory=""
                    multiple
                />
            </div>
            <DropZone onDrop={onDrop} disabled={disabled} />
            {isParsingFiles
                ? (
                    <div className="dicom-selector-loading">
                        <LoadingSpinner size={LoadingSpinnerSize.LARGE} />
                    </div>
                )
                : null
            }
        </div>
    )
}

export default DicomSelector