import { DeidentificationProtocol, TagPatchConfig } from "../../../../sharedTypes/deidentifyTypes"
import { DicomTagOverridesPerStudy } from "./types"
import { DeidentifyConfig } from "../../../../sharedTypes/deidentifyTypes"
import { generateTagPatchConfigPerStudy } from "./generateTagPatchConfigPerStudy"
import { StudyMetadata } from "../../providers/FileStateProvider"

const generateDeidentifyConfig = (
    subjectId: string,
    originalMedicalRecordNumber: string,
    mandatoryDeidentifyList: DeidentificationProtocol,
    userDefinedDeidentifyListPerStudy: Record<string, string[]>,
    dicomOverridesPerStudy: DicomTagOverridesPerStudy,
    redactBurnedInPHI: boolean,
    studyMetadata: StudyMetadata[]
): DeidentifyConfig => {
    return {
        removeBurnedInPHI: redactBurnedInPHI,
        tagPatchConfigPerStudy: generateTagPatchConfigPerStudy(
            mandatoryDeidentifyList,
            userDefinedDeidentifyListPerStudy,
            dicomOverridesPerStudy,
            studyMetadata
        ),
        subjectId,
        originalMedicalRecordNumber
    }
}

export { generateDeidentifyConfig }